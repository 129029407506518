import React from "react";
// import bgclouds from "../images/bgclouds.jpg";
// import { Link, } from "react-router-dom";
/* eslint-disable max-len */
function Footer() {
    return (

        <footer className="bg-theme-700 overflow-hidden">
            <div className="absolute w-full">
                <svg
                    className="waves"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 24 150 28"
                    preserveAspectRatio="none"
                    shapeRendering="auto"
                    // smaller size waves
                    style={{ height: "77px", minHeight: "40px", opacity: "0.5" }}
                >
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        {/* <!--Waves Colors--> */}

                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 main ">

                {/* Bottom area */}
                <div className=" footer md:flex md:items-center md:justify-between py-4 md:py-4 border-t border-theme-900">
                    <div className=" justify-center justify-items-center items-center">
                        {/* Social links */}
                        <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0 justify-center justify-items-center items-center">
                            <li className="z-50 justify-center justify-items-center items-center">
                                <a target="_blank" rel="noreferrer" href="" className="flex justify-center items-center text-theme-200 hover:text-theme-1100 bg-theme-800  rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">
                                    <svg className="w-8 h-8 fill-theme-300" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                                    </svg>
                                </a>
                            </li>
                            

                        </ul>
                    </div>
                    {/* Copyrights note */}
                    <div className="text-sm text-theme-900 mr-4">Made by Akachan Doragon. All rights reserved. C 2022</div>

                </div>

            </div>
        </footer>
    );
}

export default Footer;
